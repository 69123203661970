import React, { useState, useEffect } from 'react';
import { Grid, Button, Input, Typography, Snackbar, Alert, Box, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { deleteDoc, getDocs, collection, doc, query, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';

import Header from '../../Header';
import AlertDialog from '../../components/AlertDialog';
import DirectoryCard from '../DirectoryCard';
import NewFolderInput from '../NewFolderInput';
import { db } from '../../../firebase';
import UsersModal from '../UsersModal';

import './styles.css';

function Directories() {
	const [nameFilter, setNameFilter] = useState('');
	const [openAlert, setOpenAlert] = useState(false);
	const [itemToDelete, setItemToDelete] = useState('');
	const [directories, setDirectories] = useState([]);
	const [showNewFolderInput, setShowNewFolderInput] = useState(false);
	const [projectsExist, setProjectsExist] = useState(false);
	const [disableButtons, setDisableButtons] = useState(false);
	const [showUsers, setShowUsers] = useState(false);

	const { organizationId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		fetchDirectories();
	}, []);

	const fetchDirectories = async () => {
		const directoriesRef = collection(db, 'organizations', organizationId, 'directories');
		const querySnapshot = await getDocs(directoriesRef);
		const directories = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));

		setDirectories(directories);
	};

	const checkIfDirectoryContainsProjects = async (directory) => {
		const projectsRef = collection(db, 'organizations', organizationId, 'directories', directory.id, 'projects');
		const q = query(projectsRef);
		const querySnapshot = await getDocs(q);
		return !querySnapshot.empty;
	};

	const onDeleteDirectoryClick = (directory) => {
		setItemToDelete(directory);
		setOpenAlert(true);
	};

	const deleteDirectory = async (directory) => {
		const directoryRef = doc(db, 'organizations', organizationId, 'directories', directory.id);
		try {
			await deleteDoc(directoryRef);
			fetchDirectories();
			setOpenAlert(false);
		} catch (error) {
			console.error('Error deleting directory: ', error);
		}
	};

	const hideNewFolderInput = () => {
		setShowNewFolderInput(false);
	};

	const closeAlert = () => {
		if (!disableButtons) {
			setOpenAlert(false);
		}
	};

	const onBackClick = () => {
		navigate('/admin/organizations');
	};

	const toggleShowUsers = () => {
		setShowUsers(!showUsers);
	};

	const onAlertBoxYesClick = async () => {
		setDisableButtons(true);
		try {
			if (await checkIfDirectoryContainsProjects(itemToDelete)) {
				setProjectsExist(true);
			} else {
				await deleteDirectory(itemToDelete);
			}
		} finally {
			setDisableButtons(false);
		}
	};

	const handleClose = () => {
		setProjectsExist(false);
	};

	const renameDirectory = async (directory, newName) => {
		if (!newName.trim()) return;
		const directoryRef = doc(db, 'organizations', organizationId, 'directories', directory.id);
		try {
			await updateDoc(directoryRef, { name: newName.trim() });
			fetchDirectories(); 
		} catch (error) {
			console.error('Error renaming directory: ', error);
		}
	};

	return directories ? (
		<Grid className="directories__admin-container">
			<Header />
			<section className="directories__table-container">
				<Typography variant="h4" style={{ margin: '20px 0' }}>
					Folders
				</Typography>
				<ArrowBackIcon onClick={onBackClick} className="directories__back-button" />
				<div>
					<Input placeholder="Directory Name" onChange={(e) => setNameFilter(e.target.value)} />
					<Button
						color="secondary"
						variant="contained"
						onClick={() => setShowNewFolderInput(true)}
						startIcon={<AddIcon />}
						sx={{ marginLeft: 5 }}
					>
						ADD FOLDER
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={toggleShowUsers}
						startIcon={<ShareIcon />}
						sx={{
							borderRadius: 28,
							marginLeft: 2,
						}}
					>
						SHARE
					</Button>
				</div>
				<div className="directories__directory-container">
					{directories
						.filter((directory) => directory.name.toLowerCase().includes(nameFilter.toLowerCase()))
						.map((directory) => (
							<DirectoryCard
								key={directory.id}
								directory={directory}
								onDelete={onDeleteDirectoryClick}
								onRename={renameDirectory}
							/>
						))}
					{showNewFolderInput && (
						<NewFolderInput
							fetchDirectories={fetchDirectories}
							organizationId={organizationId}
							hideNewFolderInput={hideNewFolderInput}
						/>
					)}
				</div>
				<AlertDialog
					open={openAlert}
					onYesClick={onAlertBoxYesClick}
					handleClose={closeAlert}
					disableButtons={disableButtons}
					itemToDelete={itemToDelete}
				/>
				<Snackbar
					open={projectsExist}
					autoHideDuration={6000}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
						Cannot delete directory. Projects exist in this directory.
					</Alert>
				</Snackbar>
				<UsersModal open={showUsers} toggleModal={toggleShowUsers} organizationId={organizationId} />
			</section>
		</Grid>
	) : (
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'rgba(255, 255, 255, 0.8)',
				zIndex: 2,
			}}
		>
			<CircularProgress />
		</Box>
	);
}

export default Directories;
