import React from 'react';
import { Button, Typography, Grid, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Upload from 'rc-upload';
import Papa from 'papaparse';

import FileDisplay from '../../../common/FileDisplay';

const UploadComponent = ({ step, activeStep, csvFile, setCSVFile, images, setImages }) => {
	const moreThanFiveImages = images.length > 5;
	const [showAllImages, setShowAllImages] = React.useState(false);
	const [csvError, setCsvError] = React.useState('');
	const buttonText = showAllImages ? 'show less' : `+ ${images.length - 5} more...`;

	const toggleShowAllImages = () => setShowAllImages(!showAllImages);

	const validateCsvHeaders = (file) => {
		return new Promise((resolve, reject) => {
			Papa.parse(file, {
				delimiter: '\t',
				quoteChar: '"',
				complete: (result) => {
					if (result.errors.length) {
						reject('Error parsing CSV file.');
					} else {
						const headers = result.data[0].map((header) => header.trim());
						const requiredHeaders = ['file_name', 'latitude[deg]', 'longitude[deg]'];
						const hasAllHeaders = requiredHeaders.every((header) => headers.includes(header));
						if (hasAllHeaders) {
							resolve();
						} else {
							reject(`CSV must include: File Name , Latitude and Longitude.`);
						}
					}
				},
				error: (err) => reject(err.message),
			});
		});
	};

	const csvUploaderProps = {
		accept: '.csv',
		multiple: false,
		beforeUpload: async (file) => {
			try {
				await validateCsvHeaders(file);
				setCsvError('');
				setCSVFile(file);
			} catch (error) {
				setCsvError(error);
			}
			return false;
		},
	};

	const imageUploaderProps = {
		accept: '.jpg',
		multiple: true,
		beforeUpload: (file) => {
			setImages((currentImages) => [...currentImages, file]);
			return false;
		},
		onRemove(file) {
			const index = images.indexOf(file);
			const newImages = images.slice();
			newImages.splice(index, 1);
			setImages(newImages);
		},
	};

	return (
		<>
			<Upload {...(step === 1 ? csvUploaderProps : imageUploaderProps)} disabled={activeStep !== step}>
				<div
					className="add-project__upload-container"
					style={{
						border: `2px dashed ${activeStep === step ? '#164172' : '#ccc'}`,
						cursor: activeStep === step ? 'pointer' : '',
						pointerEvents: activeStep === step ? 'auto' : 'none',
						color: activeStep !== step ? '#CECECE' : '',
						padding: '50px 0px',
					}}
				>
					<div
						style={{
							textAlign: 'center',
						}}
					>
						<CloudUploadIcon
							style={{
								fontSize: '48px',
							}}
						/>
						<Typography
							variant="body1"
							style={{
								marginTop: '8px',
							}}
						>
							{step === 1 ? 'Drag and drop CSV here' : 'Drag and drop Images here'}
						</Typography>
						<span>or</span>
						<Button
							variant="contained"
							color="secondary"
							component="span"
							style={{
								marginTop: '8px',
								display: 'flex',
							}}
							disabled={activeStep !== step}
						>
							Browse Files
						</Button>
					</div>
				</div>
			</Upload>
			{csvError && (
				<Typography color="error" style={{ marginTop: 8 }}>
					{csvError}
				</Typography>
			)}
			{step === 1 && csvFile && (
				<FileDisplay name={csvFile.name} onRemove={() => setCSVFile(null)} isDisabled={activeStep !== step} />
			)}
			{step === 2 && images.length !== 0 && (
				<>
					<Grid container spacing={2}>
						{(showAllImages ? images : images.slice(0, 5)).map((image) => (
							<Grid item key={image.name}>
								<FileDisplay
									name={image.name}
									onRemove={() => imageUploaderProps.onRemove(image)}
									isDisabled={activeStep !== step}
								/>
							</Grid>
						))}
					</Grid>
					{moreThanFiveImages && (
						<Box style={{ marginTop: 10 }}>
							<Typography
								variant="contained"
								onClick={toggleShowAllImages}
								color="primary"
								style={{ cursor: 'pointer' }}
							>
								{buttonText}
							</Typography>
						</Box>
					)}
				</>
			)}
		</>
	);
};

export default UploadComponent;
