import React, { useState, useEffect } from 'react';
import { Grid, Button, Input, Typography, CircularProgress, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { deleteDoc, getDocs, collection, doc, query, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import Header from '../../Header';
import AlertDialog from '../../components/AlertDialog';
import ProjectCard from '../ProjectCard';
import { db } from '../../../firebase';
import { deleteProjectStorage } from '../../../utils/storageHelpers';

import './styles.css';
import { getFolderPath } from '../../../utils/utils';

function Projects() {
	const [nameFilter, setNameFilter] = useState('');
	const [openAlert, setOpenAlert] = useState(false);
	const [projects, setProjects] = useState([]);
	const [itemToDelete, setItemToDelete] = useState('');
	const [disableButtons, setDisableButtons] = useState(false);

	const { organizationId, directoryId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		fetchProjects();
	}, []);

	const fetchProjects = async () => {
		const projectsRef = collection(db, 'organizations', organizationId, 'directories', directoryId, 'projects');

		const q = query(projectsRef);
		const querySnapshot = await getDocs(q);

		const projects = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			name: doc.data().name,
			creationDate: doc.data().creationDate,
			images: doc.data().images,
			csvURL: doc.data().csvURL,
		}));

		setProjects(projects);
	};

	const getFilteredProjects = (projects) => {
		const filteredItemsOnNameFilter = projects.filter((item) => {
			let filterName = nameFilter.toLowerCase();
			const names = item.name.split(' ');
			for (let i = 0; i < names.length; i += 1) {
				const name = names[i];
				if (name.toLowerCase().startsWith(filterName)) {
					return true;
				}
			}
			return false;
		});
		return filteredItemsOnNameFilter;
	};

	const closeAlert = () => {
		if (!disableButtons) {
			setOpenAlert(false);
		}
	};

	const onDeleteProjectClick = (project) => {
		setItemToDelete(project);
		setOpenAlert(true);
	};

	const deleteProject = async (project) => {
		setDisableButtons(true);
		const projectRef = doc(db, 'organizations', organizationId, 'directories', directoryId, 'projects', project.id);
		try {
			const folderPath = getFolderPath(organizationId, project.id);
			await deleteProjectStorage(folderPath);
			await deleteDoc(projectRef);
			fetchProjects();
			setOpenAlert(false);
		} catch (error) {
			console.error('Error deleting project: ', error);
		} finally {
			setDisableButtons(false);
		}
	};

	const onBackClick = () => {
		navigate(`/admin/organizations/${organizationId}/directories`);
	};

	const onAlertBoxYesClick = () => {
		deleteProject(itemToDelete);
	};

	const onAddProjectClick = () => {
		navigate(`/admin/organizations/${organizationId}/directories/${directoryId}/new-project`);
	};

	const renameProject = async (project, newName) => {
		const projectRef = doc(db, 'organizations', organizationId, 'directories', directoryId, 'projects', project.id);

		try {
			await updateDoc(projectRef, { name: newName });
			fetchProjects(); 
		} catch (error) {
			console.error('Error renaming project:', error);
		}
	};

	const filteredProjects = getFilteredProjects(projects);

	return projects ? (
		<Grid className="projects__admin-container">
			<Header />
			<section className="projects__table-container">
				<Typography variant="h4" style={{ margin: '20px 0' }}>
					Projects
				</Typography>
				<ArrowBackIcon onClick={onBackClick} className="projects__back-button" />
				<div>
					<Input placeholder="Project Name" onChange={(e) => setNameFilter(e.target.value)} />
					<Button
						color="secondary"
						variant="contained"
						startIcon={<AddIcon />}
						sx={{ marginLeft: 10 }}
						onClick={onAddProjectClick}
					>
						ADD PROJECT
					</Button>
				</div>
				<div className="projects__project-card-container">
					{filteredProjects.map((project) => (
						<ProjectCard
							key={project.id}
							project={project}
							onEdit={() => {}}
							onDelete={onDeleteProjectClick}
							onRename={renameProject}
						/>
					))}
				</div>
				<AlertDialog
					open={openAlert}
					onYesClick={onAlertBoxYesClick}
					handleClose={closeAlert}
					disableButtons={disableButtons}
					itemToDelete={itemToDelete}
				/>
			</section>
		</Grid>
	) : (
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'rgba(255, 255, 255, 0.8)',
				zIndex: 2,
			}}
		>
			<CircularProgress />
		</Box>
	);
}

export default Projects;
