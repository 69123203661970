import React, { useState, useRef, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageIcon from '@mui/icons-material/Image';
import TodayIcon from '@mui/icons-material/Today';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, IconButton, Menu, MenuItem, Input } from '@mui/material';
import ProjectIcon from '../../../assets/project-icon.png';

import './styles.css';

const ProjectCard = ({ project, onEdit, onDelete, onRename }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [isRenaming, setIsRenaming] = useState(false);
	const [newName, setNewName] = useState(project.name); 
	const inputRef = useRef(null); 
	const cardRef = useRef(null); 
	const open = Boolean(anchorEl);

	const handleMenuClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleRenameSubmit = () => {
		if (newName.trim() && newName.trim() !== project.name) {
			onRename(project, newName.trim());
		}
		setIsRenaming(false);
	};

	const handleOutsideClick = (e) => {
		if (
			inputRef.current &&
			cardRef.current &&
			!inputRef.current.contains(e.target) &&
			!cardRef.current.contains(e.target)
		) {
			handleMenuClose();
			setIsRenaming(false);
		}
	};

	useEffect(() => {
		if (isRenaming) {
			document.addEventListener('mousedown', handleOutsideClick);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
		}

		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [isRenaming]);

	return (
		<Card
			ref={cardRef}
			sx={{
				width: 350,
				height: 100,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'stretch',
				justifyContent: 'space-between',
				padding: '4px',
				position: 'relative',
			}}
		>
			<div style={{ display: 'flex', alignItems: 'stretch' }}>
				<CardMedia
					component="img"
					sx={{ width: 100, height: '100%', objectFit: 'cover', borderRadius: 1 }}
					image={ProjectIcon}
					alt="Project Icon"
				/>
				<div style={{ margin: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<div
						className="project-card"
						style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
						onClick={() => !isRenaming && onEdit(project)}
					>
						{isRenaming ? (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Input
									autoFocus
									ref={inputRef}
									value={newName}
									onChange={(e) => setNewName(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === 'Enter') handleRenameSubmit();
									}}
									size="small"
									sx={{ flexGrow: 1, padding: '5px', marginRight: '8px' }}
								/>
								<IconButton
									onClick={handleRenameSubmit}
									size="small"
									sx={{ padding: 0, marginRight: '8px' }}
								>
									<CheckIcon fontSize="small" />
								</IconButton>
								<IconButton onClick={() => setIsRenaming(false)} size="small" sx={{ padding: 0 }}>
									<CloseIcon fontSize="small" />
								</IconButton>
							</div>
						) : (
							<div className="project-card__name">{project.name}</div>
						)}
						<div style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
							<ImageIcon fontSize="small" style={{ display: 'block' }} />
							{project.images?.length}
						</div>
						<div style={{ fontSize: 14, display: 'flex', alignItems: 'center', marginTop: 10 }}>
							<TodayIcon fontSize="small" style={{ display: 'block' }} />
							{project.creationDate.toDate().toLocaleDateString('en-US', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
							})}
						</div>
					</div>
				</div>
			</div>
			<div className="directory-card__menu" style={{ position: 'absolute', top: 0, right: 0 }}>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuClick}>
					{isRenaming ? null : <MoreVertIcon />}
				</IconButton>
				<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleMenuClose}>
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							setIsRenaming(true);
							handleMenuClose();
						}}
					>
						Rename
					</MenuItem>
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							onDelete(project);
							handleMenuClose();
						}}
					>
						Delete
					</MenuItem>
				</Menu>
			</div>
		</Card>
	);
};

export default ProjectCard;
