import axios from 'axios';
import firebaseConfig from '../firebaseConfig';
import { auth } from '../firebase';

export const callFirebaseFunction = async (functionName, body = {}) => {
	const token = await auth.currentUser.getIdToken(true);
	const functionURL = `https://${firebaseConfig.defaultRegion}-${firebaseConfig.projectId}.cloudfunctions.net/${functionName}`;

	try {
		const response = await axios.post(functionURL, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response ? error.response.data.message : 'Failed to create user');
	}
};

export const extractToken = (downloadURL) => {
	const parts = downloadURL.split('token=');
	const token = parts[1];
	return token;
};

export const constructURL = (orgId, projectId, projectName, fileName, token) => {
	const baseURL = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/`;
	const path = `${orgId}%2F${projectId}%2Fimages%2F${fileName}`;
	const url = `${baseURL}${path}?alt=media&token=${token}`;
	return url;
};