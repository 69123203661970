import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { getDocs, collection, doc, getDoc } from 'firebase/firestore';

import SideBar from './Sidebar';
import CustomMap from '../CustomMap';
import MediaDisplay from '../MediaDisplay';
import Header from '../Header';
import { auth, db } from '../../firebase';

import './styles.css';
import { ROLE_PRIVILEGES } from '../../common/consts';

const Home = () => {
	const [csvDataRead, setCsvDataRead] = useState(false);
	const [directories, setDirectories] = useState([]);
	const [projectInfo, setProjectInfo] = useState();
	const [selectedOrganization, setSelectedOrganization] = useState();
	const [loading, setLoading] = useState(false);
	const [currentImage, setCurrentImage] = useState();
	const [currentIndex, setcurrentIndex] = useState(0);
	const [imageIndexes, setImageIndexes] = useState({
		fromIndex: 0,
		toIndex: 9,
	})
	const [user, setUser] = useState();
	const [organizations, setOrganizations] = useState([]);

	useEffect(() => {
		auth.currentUser.getIdTokenResult().then((idTokenResult) => {
			const claims = idTokenResult.claims;
			setUser({
				email: claims.email,
				name: claims.name,
				organizationIds: claims.organizationIds || [],
				privilegeLevel: claims.privilegeLevel,
			});
		});
	}, [auth.currentUser]);

	useEffect(() => {
		if (selectedOrganization) {
			fetchDirectories();
		}
	}, [selectedOrganization]);

	useEffect(() => {
		if (projectInfo?.images.length > 0) {
			updateInitialImage();
		}
	}, [projectInfo]);

	useEffect(() => {
		if (user) {
			fetchOrganizations();
		}
	}, [user]);

	const updateInitialImage = () => {
		setCurrentImage({
			fileName: projectInfo.images[0].fileName,
			token: projectInfo.images[0].token,
		});
	};

	const fetchOrganizations = async () => {
		let organizations = [];

		if (user.privilegeLevel === ROLE_PRIVILEGES.USER) {
			const organizationPromises = user.organizationIds.map((orgId) => {
				const organizationRef = doc(db, 'organizations', orgId);
				return getDoc(organizationRef).then((querySnapshot) => {
					const data = querySnapshot.data();
					return { id: orgId, ...data };
				});
			});
			organizations = await Promise.all(organizationPromises);
		} else {
			const querySnapshot = await getDocs(collection(db, 'organizations'));
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				organizations.push({
					id: doc.id,
					name: data.name,
				});
			});
		}

		setOrganizations(organizations);
		setSelectedOrganization(organizations[0].id);
	};

	const fetchDirectories = async () => {
		const directoriesRef = collection(db, 'organizations', selectedOrganization, 'directories');
		const querySnapshot = await getDocs(directoriesRef);
		let directories = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			directories.push({
				id: doc.id,
				name: data.name,
			});
		});
		setDirectories(directories);
	};

	const loadProject = (project) => {
		setImageIndexes({
			fromIndex: 0,
			toIndex: 9,
		})
		setcurrentIndex(0);
		setProjectInfo(project);
		setCsvDataRead(false);
	};

	const nextImage = () => {
		setLoading(true);
		setcurrentIndex((currentIndex) => {
			let newIndex = currentIndex + 1;
			if (newIndex >= projectInfo.images.length) {
				newIndex = 0;
			}
			if (newIndex > imageIndexes.toIndex - 4) {
				setImageIndexes(((prevState) => ({
					fromIndex: Math.max(currentIndex - 4, 0),
					toIndex: Math.min(prevState.toIndex + 4, projectInfo.images.length),
				})))
			}
			setCurrentImage({
				fileName: projectInfo.images[newIndex].fileName,
				token: projectInfo.images[newIndex].token,
			});
			setLoading(false);
			return newIndex;
		});
	};

	const prevImage = () => {
		setLoading(true);
		if (currentIndex !== 0) {
			setcurrentIndex((currentIndex) => {
				let newIndex = currentIndex - 1;
				if (newIndex < 0) {
					newIndex = projectInfo.images.length - 1;
				}
				if (newIndex < imageIndexes.fromIndex) {
					setImageIndexes((prevState) => ({
						fromIndex: Math.max(prevState.fromIndex - 4, 0),
						toIndex: newIndex + 4,
					}))
				}
				setCurrentImage({
					fileName: projectInfo.images[newIndex].fileName,
					token: projectInfo.images[newIndex].token,
				});
				setLoading(false);
				return newIndex;
			});
		}
	};

	const switchOrganization = (organizationId) => {
		setSelectedOrganization(organizationId);
	};

	const onClickMarker = (index) => {
		const newIndex = index >= projectInfo.images.length ? 0 : index;
		setcurrentIndex(newIndex);
		setImageIndexes({
			fromIndex: Math.max(index - 4, 0),
			toIndex: Math.min(index + 4, projectInfo.images.length),
		})
		setCurrentImage({
			fileName: projectInfo.images[index].fileName,
			token: projectInfo.images[index].token,
		});
	};

	return organizations ? (
		<div style={{ display: 'flex' }}>
			<section>
				<SideBar
					loadProject={loadProject}
					directories={directories}
					organizationId={selectedOrganization}
					user={user}
					organizations={organizations}
					switchOrganization={switchOrganization}
				/>
			</section>
			<section className="home__page-content">
				<Header />
				<Grid container>
					<CustomMap
						csvURL={projectInfo?.csvURL}
						csvDataRead={csvDataRead}
						updateCSVDataReadingState={(read) => setCsvDataRead(read)}
						currentImageFileName={currentImage?.fileName}
						onClickMarker={onClickMarker}
						currentImageIndex={currentIndex}
					/>
					<MediaDisplay
						nextImage={nextImage}
						prevImage={prevImage}
						images={projectInfo?.images || []}
						imageIndexes={imageIndexes}
						loading={loading}
						currentImage={currentImage}
						orgId={selectedOrganization}
						projectId={projectInfo && projectInfo.id}
						projectName={projectInfo && projectInfo.name}
					/>
				</Grid>
			</section>
		</div>
	) : (
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'rgba(255, 255, 255, 0.8)',
				zIndex: 2,
			}}
		>
			<CircularProgress />
		</Box>
	);
};

export default Home;
