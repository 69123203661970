import { ROLE_PRIVILEGES } from '../common/consts';

export function getFolderPath(organizationId, projectId) {
	return `${organizationId}/${projectId}`;
}

export const getSuffix = () => {
	return process.env.REACT_APP_FIREBASE_ENV === 'production' ? '_4000x2000.jpg' : '_2000x1000.jpg';
}

export function getFileNameWithSuffix(fileName) {
	let baseName = fileName;
	if (fileName.includes('.jpg')) {
		baseName = fileName.substring(0, fileName.length - 4);
	}
	// Use '_4000x2000.jpg' in production; default to '_2000x1000.jpg' otherwise.
	return `${baseName}${getSuffix()}`;
}

export function formatRoleName(role) {
	return role.replace('_', ' ');
}

export function getRoleFromPrivilege(privilegeLevel) {
	return Object.keys(ROLE_PRIVILEGES).find((key) => ROLE_PRIVILEGES[key] === privilegeLevel);
}

const getDistanceBetweenTwoPoints = (point1, point2) => {
	const x1 = point1[0];
	const y1 = point1[1];
	const x2 = point2[0];
	const y2 = point2[1];

	const l1 = Math.pow(x1 - x2, 2);
	const l2 = Math.pow(y1 - y2, 2);

	return Math.sqrt(l1 + l2);
};

export const getClosestCoordinateIndex = (coordinate, coordinates) => {
	let closestCoOrdinate = ''
	let closestCoOrdinateIndex = 0;
	let minimumDistance = 99999999999;
	coordinates.forEach((currentCoordinate, index) => {
		const distance = getDistanceBetweenTwoPoints(
			coordinate,
			[currentCoordinate.latitude, currentCoordinate.longitude]
		);
		if (distance < minimumDistance) {
			minimumDistance = distance;
			closestCoOrdinate = currentCoordinate;
			closestCoOrdinateIndex = index;
		}
	});
	return { coordinate: closestCoOrdinate, index: closestCoOrdinateIndex };
};