import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, Polyline } from 'react-leaflet';
import L from 'leaflet';
import { Card, Grid } from '@mui/material';
import 'leaflet/dist/leaflet.css';

import { apiKey } from '../../common/config';
import { getClosestCoordinateIndex, getFileNameWithSuffix } from '../../utils/utils';
import './styles.css';

const INITIAL_CENTER_LATITUDE = 39.520139;
const INITIAL_CENTER_LONGITUDE = 44.671443;

const markerIcon = new L.icon({
	iconUrl: require('../../assets/icons/marker.png'),
	iconSize: [20, 20],
});

const MapEvents = ({ center, setZoom, zoom, onMapClick, initialZoomedIn, setInitialZoomedIn }) => {
	const map = useMapEvents({
		zoomend(e) {
			setZoom(e.target.getZoom());
		},
		click: onMapClick,
	});

	useEffect(() => {
		if (initialZoomedIn || center[0] === INITIAL_CENTER_LATITUDE) {
			map.panTo(center);
		} else {
			map.flyTo(center, 18, { duration: 2 });
			setInitialZoomedIn(true);
		}
	}, [center]);

	useEffect(() => {
		if (initialZoomedIn) {
			map.setZoom(zoom);
		}
	}, [zoom]);

	return null;
};

const CustomMap = ({
	csvURL,
	updateCSVDataReadingState,
	csvDataRead,
	currentImageFileName,
	onClickMarker,
	currentImageIndex,
}) => {
	const [center, setCenter] = useState([INITIAL_CENTER_LATITUDE, INITIAL_CENTER_LONGITUDE]);
	const [zoom, setZoom] = useState(3);
	const [timeAndCoordinates, setTimeAndCoordinates] = useState([]);
	const [initialZoomedIn, setInitialZoomedIn] = useState(false);

	useEffect(() => {
		if (csvURL && !csvDataRead) {
			if (zoom !== 18) {
				setInitialZoomedIn(false);
			}
			readCSV(csvURL);
		}
	}, [csvURL]);

	const readCSV = async (url) => {
		var text = '';
		var xhr = new XMLHttpRequest();
		xhr.open('GET', url);
		xhr.onload = () => {
			if (xhr.status === 200) {
				text = xhr.response;
				analyzeCSVdata(text);
			}
			text = xhr.response;
		};
		xhr.send();
		updateCSVDataReadingState(true);
	};

	const analyzeCSVdata = (text) => {
		const timeAndCoordinatesRead = [];
		const rows = text.split('\n');
		for (let i = 1; i < rows.length; i += 1) {
			const currentRow = rows[i].split('\t');
			if (currentRow.length >= 4) {
				const timeAndCoordinate = {
					gpsSeconds: parseFloat(currentRow[0]),
					fileName: getFileNameWithSuffix(currentRow[1]),
					latitude: parseFloat(currentRow[2]),
					longitude: parseFloat(currentRow[3]),
				};
				timeAndCoordinatesRead.push(timeAndCoordinate);
			}
		}
		setTimeAndCoordinates(timeAndCoordinatesRead);
	};

	useEffect(() => {
		if (timeAndCoordinates.length > 0) {
			const firstCoordinate = timeAndCoordinates[0];
			setCenter([firstCoordinate.latitude, firstCoordinate.longitude]);
		}
	}, [timeAndCoordinates]);

	useEffect(() => {
		if (timeAndCoordinates.length !== 0) {
			const currentCoordinate = timeAndCoordinates[currentImageIndex];
			setCenter([currentCoordinate.latitude, currentCoordinate.longitude]);
		}
	}, [currentImageFileName]);

	const onMapClick = (e) => {
		const clickedCoordinate = [e.latlng.lat, e.latlng.lng];

		const { coordinate, index } = getClosestCoordinateIndex(clickedCoordinate, timeAndCoordinates);

		setCenter([coordinate.latitude, coordinate.longitude]);
		onClickMarker(index);
	};

	return (
		<Grid className="custom-map__container">
			<Card className={`custom-map__upload-map-component ${csvURL ? '' : 'custom-map__blurred'}`}>
				<MapContainer
					center={center}
					zoom={zoom}
					className="custom-map__custom-map"
					onZoom={(e) => setZoom(e.target.getZoom())}
					style={{
						height: '100%',
						width: '100%',
					}}
					attributionControl={false}
					onMapClick={onMapClick}
				>
					<TileLayer url={`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?apikey=${apiKey}`} />
					{timeAndCoordinates.length !== 0 && (
						<Polyline
							positions={timeAndCoordinates.map((tc) => [tc.latitude, tc.longitude])}
							weight={4}
							color="#1a73ba"
						/>
					)}
					{timeAndCoordinates.length !== 0 && (
						<>
							<Marker position={center} icon={markerIcon} />
							<MapEvents
								center={center}
								setZoom={setZoom}
								zoom={zoom}
								onMapClick={onMapClick}
								initialZoomedIn={initialZoomedIn}
								setInitialZoomedIn={setInitialZoomedIn}
							/>
						</>
					)}
				</MapContainer>
			</Card>
		</Grid>
	);
};

export default CustomMap;
