import React, { useState, useEffect, useRef } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@mui/material/Input';

import './styles.css';
import { useNavigate, useParams } from 'react-router-dom';

const DirectoryCard = ({ directory, onDelete, inAddMode = false, onRename, onNameChange, onSubmit }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [isRenaming, setIsRenaming] = useState(false);
	const [newName, setNewName] = useState(directory.name || '');
	const open = Boolean(anchorEl);
	const inputRef = useRef(null);
	const cardRef = useRef(null);
	
	const navigate = useNavigate();
	const { organizationId } = useParams();

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDirectoryClick = (directory) => {
		navigate(`/admin/organizations/${organizationId}/directories/${directory.id}/projects`);
	};

	const handleRenameSubmit = () => {
		setIsRenaming(false);
		onRename(directory, newName);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			onSubmit && onSubmit();
		}
	};

	const handleOutsideClick = (e) => {
		if (
			inputRef.current &&
			cardRef.current &&
			!inputRef.current.contains(e.target) &&
			!cardRef.current.contains(e.target)
		) {
			setIsRenaming(false);
		}
	};

	useEffect(() => {
		if (isRenaming) {
			document.addEventListener('mousedown', handleOutsideClick);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
		}

		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [isRenaming]);

	return (
		<div
			ref={cardRef}
			className="directory-card"
			onClick={inAddMode || isRenaming ? null : () => handleDirectoryClick(directory)}
		>
			<div className="directory-card__icon">
				<FolderIcon />
			</div>
			{inAddMode ? (
				<Input
					autoFocus
					size="small"
					variant="outlined"
					placeholder="New Folder Name"
					onChange={(e) => onNameChange(e.target.value)}
					onKeyDown={handleKeyDown}
					onBlur={onSubmit}
				/>
			) : isRenaming ? (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Input
						autoFocus
						size="small"
						variant="outlined"
						placeholder="Folder Name"
						value={newName}
						onChange={(e) => setNewName(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleRenameSubmit();
							}
						}}
						ref={inputRef}
					/>
					<IconButton onClick={handleRenameSubmit} size="small" sx={{ padding: 0, marginRight: '8px' }}>
						<CheckIcon fontSize="small" />
					</IconButton>
					<IconButton onClick={() => setIsRenaming(false)} size="small" sx={{ padding: 0 }}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>
			) : (
				<div className="directory-card__name">{directory.name}</div>
			)}
			{!inAddMode && !isRenaming && (
				<div className="directory-card__menu">
					<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
					<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
						<MenuItem
							onClick={(e) => {
								e.stopPropagation();
								setIsRenaming(true);
								handleClose();
							}}
						>
							Rename
						</MenuItem>
						<MenuItem
							onClick={(e) => {
								e.stopPropagation();
								onDelete(directory);
								handleClose();
							}}
						>
							Delete
						</MenuItem>
					</Menu>
				</div>
			)}
		</div>
	);
};

export default DirectoryCard;
